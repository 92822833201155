<template>
  <div class="article" v-html="article">
  </div>
</template>

<script>

export default {
  name: "TalkDetail.vue",
  props:['id'],
  data(){
    return{
      article:'加载中...'
    }
  },
  async mounted(){
    this.$emit("getMessage", false);
    this.article = await this.readFile('/article/'+ this.id +'.txt')
    // console.log(this.id, this.article)
  }
}
</script>

<style lang="less" scoped>
.article{
  padding-top: 48px;
  background-color: rgb(236, 246, 251);
  min-height: 100%;
}
</style>